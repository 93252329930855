import { Controller } from "stimulus";
import { requestPost } from "lib/request";
import I18n from "i18n-js";
import Toastr from "toastr";

export default class extends Controller {
  static targets = [
    "card",
    "cardContainer",
    "methodContainer",
    "paymentTotal",
    "channel",
    "method",
    "planId",
    "payUrl",
    "methodId",
    "paymentForm",
    "payResultModal",
    "paymentLink",
    "successfulMessage",
    "failedMessage",
    "submitBtn",
  ];

  connect() {
    if (this.cardTargets.length > 0) {
      // 设置plan和method的点击事件
      this.cardTargets.forEach((card) => {
        card.addEventListener("click", () => {
          if (!card.classList.contains("is-active")) {
            this.removeAllCardActiveClass();
            card.classList.add("is-active");
            this.updateChannelForPlan();
            this.afterUpdateForPlan(card);
          }
        });
      });
      this.cardTargets[0].classList.add("is-active");
      // 设置对应的通道可见并默认激活第一个
      this.updateChannelForPlan();
    }

    if (this.methodTargets.length > 0) {
      this.methodTargets.forEach((method) => {
        method.addEventListener("click", () => {
          if (!method.classList.contains("is-active")) {
            this.removeAllMethodActiveClass();
            method.classList.add("is-active");
            this.updateFormMethodId();
          }
        });
      });
    }
  }

  updateChannelForPlan() {
    let activedPlan = document.querySelector(
      ".plans .card-container .is-active"
    );
    if (activedPlan) {
      let planId = activedPlan.dataset.planId;
      this.updateChannelStatus(planId);
      this.afterUpdateForPlan(activedPlan);
    }
  }

  updateChannelStatus(planId) {
    if (this.channelTargets.length > 0) {
      this.channelTargets.forEach((channel) => {
        if (channel.dataset.planId === planId) {
          channel.style.display = "flex";
          // 选中第一个通道
          let firstMethod = channel.querySelector(".method-item");
          if (firstMethod) {
            firstMethod.classList.add("is-active");
            this.updateFormMethodId();
          }
        } else {
          channel.style.display = "none";
          // 去掉已选中的通道
          let activedMethods = channel.querySelectorAll(
            ".method-item.is-active"
          );
          if (activedMethods) {
            activedMethods.forEach((method) => {
              method.classList.remove("is-active");
            });
          }
        }
      });
    }
  }

  createOrder() {
    this.submitBtnTarget.classList.add("is-loading");
    this.submitBtnTarget.disabled = true;
    let methodId = this.methodIdTarget.value;
    let url = this.cardContainerTarget.dataset.postPath;
    requestPost(
      url,
      {
        plan: {
          id: this.planIdTarget.value,
          method_id: this.methodIdTarget.value,
        },
      },
      (data) => {
        if (data.success) {
          if (methodId == "0" || methodId == "-1") {
            if (data.url != "") {
              window.location.href = data.url;
            } else {
              this.failedMessageTarget.hidden = false;
              this.successfulMessageTarget.hidden = true;
              this.payResultModalTarget.classList.add("is-active");
            }
          } else {
            if (data.payment_url) {
              this.failedMessageTarget.hidden = true;
              this.successfulMessageTarget.hidden = false;
              this.paymentLinkTarget.setAttribute("href", data.payment_url);
            } else {
              this.failedMessageTarget.hidden = false;
              this.successfulMessageTarget.hidden = true;
            }
            this.payResultModalTarget.classList.add("is-active");
            this.submitBtnTarget.classList.remove("is-loading");
            this.submitBtnTarget.disabled = false;
          }
        } else {
          this.failedMessageTarget.querySelector("strong.title").innerText =
            data.message;
          this.payResultModalTarget.classList.add("is-active");
          this.failedMessageTarget.hidden = false;
          this.successfulMessageTarget.hidden = true;
          this.submitBtnTarget.classList.remove("is-loading");
          this.submitBtnTarget.disabled = false;
        }
      }
    );
  }

  createJoyOrder() {
    this.submitBtnTarget.classList.add("is-loading");
    this.submitBtnTarget.disabled = true;
    let self = this;
    let url = this.cardContainerTarget.dataset.postPath;
    requestPost(
      url,
      {
        plan_id: this.submitBtnTarget.dataset.planId,
      },
      (data) => {
        if (data.success) {
          let form = document.createElement("form");
          form.action = data.data.url;
          form.method = "post";
          self.element.appendChild(form);
          let result = data.data;
          Object.keys(result).forEach((key) => {
            let inputElement = document.createElement("input");
            inputElement.type = "hidden";
            inputElement.name = key;
            inputElement.value = result[key];
            form.appendChild(inputElement);
          });
          form.submit();
        }
      }
    );
  }

  afterUpdateForPlan(element) {
    // update total
    this.paymentTotalTarget.innerHTML = element.querySelector(
      ".price"
    ).innerText;
    // update plan id
    if (this.hasPlanIdTarget) {
      this.planIdTarget.value = element.dataset.planId;
    }
    if (this.hasSubmitBtnTarget) {
      this.submitBtnTarget.dataset.planId = element.dataset.planId;
    }
    // update pay url
    // this.payUrlTarget.value = element.dataset.payUrl;
  }

  updateFormMethodId() {
    let element = this.element.querySelector(".method-item.is-active");
    this.methodIdTarget.value = element.dataset.methodId;
  }

  removeAllCardActiveClass() {
    let elements = document.querySelectorAll(
      ".plans .card-container .is-active"
    );
    Array.from(elements).forEach((element) => {
      element.classList.remove("is-active");
    });
  }

  removeAllMethodActiveClass() {
    let elements = this.element.querySelectorAll(".method-item.is-active");
    Array.from(elements).forEach((element) => {
      element.classList.remove("is-active");
    });
  }
}
