import { Controller } from "stimulus";
import I18n from "i18n-js";
import { initI18n } from "locales/i18n.js";
import "select2";
import "select2/dist/js/i18n/zh-TW";
import { currentLocale } from "src/common";

export default class extends Controller {
  static targets = ["type"];

  initialize() {
    initI18n();
    I18n.defaultLocale = currentLocale();
    I18n.locale = currentLocale();
  }

  connect() {
    this.initSelectInputs();
  }

  initSelectInputs() {
    let selfInstance = this;
    let inputs = document.querySelectorAll("select.select2");
    if (inputs) {
      Array.from(inputs).forEach(function (input) {
        $(input).select2({
          language: currentLocale(),
          width: "100%",
          minimumInputLength: 1,
          maximumInputLength: 40,
          cache: true,
          placeholder: I18n.t("input_and_select"),
          allowClear: true,
          ajax: {
            delay: 800,
            url: input.dataset.getUrl,
            data: (params) => {
              let movieType = selfInstance.typeTarget.value;
              if (movieType == "3") movieType = null;
              return { q: params.term, t: movieType };
            },
            processResults: (results) => ({
              results: results.map(function (result) {
                let data = {
                  id: `${result.id}|${result.name}`,
                  text: result.name,
                };
                if ("gender" in result) {
                  data.gender = result.gender;
                }
                if ("avatar_url" in result) {
                  data.avatar_url = result.avatar_url;
                }
                if ("other_name" in result) {
                  data.other_name = result.other_name;
                }
                if ("videos_count" in result) {
                  data.videos_count = result.videos_count;
                }
                return data;
              }),
            }),
          },
          templateResult: selfInstance.formatResult,
          templateSelection: selfInstance.formatResultSelection,
        });
      });
    }
  }

  formatResult(result) {
    if (result.loading) {
      return result.text;
    }

    let container = $(
      "<div class='select2-result'>" +
        "<div class='info'><div class='title'></div></div>" +
        "</div>"
    );
    if ("avatar_url" in result) {
      container = $(
        "<div class='select2-result select2-has-avatar'>" +
          "<div class='avatar'><img src='" +
          result.avatar_url +
          "' /></div>" +
          "<div class='info'><div class='title'></div></div>" +
          "</div>"
      );
    }
    let text = result.text;
    if ("gender" in result) {
      text += ` (${I18n.t("gender" + result.gender)})`;
    }
    if ("videos_count" in result) {
      text += ` (${result.videos_count})`;
    }
    container.find(".info .title").text(text);
    if ("other_name" in result) {
      let elm = document.createElement("div");
      elm.classList.add("meta");
      elm.textContent = result.other_name;
      container.find(".info").append(elm);
    }
    return container;
  }

  formatResultSelection(result) {
    if ("gender" in result) {
      return `${result.text}${result.gender == 0 ? "♀" : "♂"}`;
    } else {
      return result.text;
    }
  }
}
