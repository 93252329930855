import Cookies from "js-cookie";
import I18n from "i18n-js";

export function getTheme() {
  let preference = document.querySelector("meta[name='theme']");
  let theme = "light";
  if (preference) {
    theme = preference.content;
    if (theme === "auto") {
      if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
        return "dark";
      } else {
        return "light";
      }
    }
  }
  return theme;
}

export function switchTheme() {
  let theme = getTheme();
  document.documentElement.setAttribute("data-theme", theme);
}

export function currentLocale() {
  let systemLocale = document.querySelector("body").dataset.lang;
  if (systemLocale == null || systemLocale == "") {
    return "zh-TW";
  }
  return systemLocale == "zh" ? "zh-TW" : systemLocale;
}

export function closeModals() {
  let modals = document.querySelectorAll("div.modal.is-active");
  Array.from(modals).forEach(function (modal) {
    modal.classList.remove("is-active");
  });
}

export function repositionCovers() {
  let items = document.querySelectorAll(
    ".movie-list.h .item .cover:not(.contain)"
  );
  if (items) {
    items.forEach((item) => {
      let img = item.querySelector("img");
      if (img.complete) {
        if (img.offsetWidth > 0 && img.offsetWidth < item.offsetWidth) {
          item.classList.add("contain");
        }
      } else {
        img.addEventListener("load", (e) => {
          if (img.offsetWidth > 0 && img.offsetWidth < item.offsetWidth) {
            item.classList.add("contain");
          }
        });
      }
    });
  }
  let allItems = document.querySelectorAll(".movie-list .item .cover img");
  if (allItems) {
    allItems.forEach((item) => {
      item.addEventListener("error", (e) => {
        let imgPrefix = document.querySelector("link[rel='preconnect']").href;
        item.src = `${imgPrefix}images/noimage_300x169.jpg`;
      });
    });
  }
}

export function initModals(options = { container: document }) {
  // modal button
  let modalButtons = options.container.querySelectorAll(".modal-button");
  if (modalButtons) {
    modalButtons.forEach((button) => {
      button.addEventListener("click", (e) => {
        let self = e.currentTarget;
        let isAuth = self.dataset.auth === true;
        if (isAuth) {
          beforeAuth(function (data) {
            if (data) {
              options.container
                .querySelector(`#${self.dataset.target}`)
                .classList.add("is-active");
            } else {
              window.location.href = "/login" + "?t=" + new Date().getTime();
            }
          });
        } else {
          options.container
            .querySelector(`#${self.dataset.target}`)
            .classList.add("is-active");
        }
      });
    });
  }

  let modalCloseButtons = options.container.querySelectorAll(
    ".modal-card-head .delete, .button-modal-close, .modal .modal-close"
  );
  if (modalCloseButtons) {
    modalCloseButtons.forEach((button) => {
      button.addEventListener("click", (e) => {
        closeModals();
      });
    });
  }

  let modalBgs = options.container.querySelectorAll(
    ".modal .modal-background:not(.unclose)"
  );
  if (modalBgs) {
    modalBgs.forEach((bg) => {
      bg.addEventListener("click", (e) => {
        closeModals();
      });
    });
  }
}

export function closeNotification(e) {
  Cookies.set("notification_hide", "1", { expires: 14 });
  $(e).parent().hide();
}

export function beforeAuth(callback) {
  $.get("/users/auth", function (data) {
    if (callback != null && $.isFunction(callback)) {
      callback(data == "1");
    } else {
      callback(false);
    }
  });
}

export function show(elem) {
  elem.style.display = "block";
}

export function hide(elem) {
  elem.style.display = "none";
}

export function showModal(elem) {
  if (!elem.classList.contains("is-active")) {
    elem.classList.add("is-active");
  }
}

export function hideModal(elem) {
  if (elem.classList.contains("is-active")) {
    elem.classList.remove("is-active");
  }
}

export function toggle(elem) {
  if (elem.style.display === "block" || elem.style.display === "") {
    hide(elem);
    return;
  }
  show(elem);
}

export function switchTagExpend(t, status) {
  if (status) {
    // expend
    t.removeClass("is-outlined");
    t.data("active", "true");
    t.find("span.text").text(I18n.t("collapse"));
    t.find("span.icon i")
      .removeClass("icon-angle-double-down")
      .addClass("icon-angle-double-up");
  } else {
    // collspse
    t.addClass("is-outlined");
    t.data("active", "false");
    t.find("span.text").text(I18n.t("more"));
    t.find("span.icon i")
      .removeClass("icon-angle-double-up")
      .addClass("icon-angle-double-down");
  }
}

export function removeParam(sourceURL, key) {
  var rtn = sourceURL.split("?")[0],
    param,
    params_arr = [],
    queryString = sourceURL.indexOf("?") !== -1 ? sourceURL.split("?")[1] : "";
  if (queryString !== "") {
    params_arr = queryString.split("&");
    for (var i = params_arr.length - 1; i >= 0; i -= 1) {
      param = params_arr[i].split("=")[0];
      if (param === key) {
        params_arr.splice(i, 1);
      }
    }
    if (params_arr.length > 0) {
      rtn = rtn + "?" + params_arr.join("&");
    } else {
      rtn = rtn;
    }
  }
  return rtn;
}

export function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function convertShortNumber(locale) {
  let spans = document.querySelectorAll("span.short-number");
  if (spans) {
    spans.forEach((span) => {
      console.log(span.innerText);
      let intNumber = parseInt(span.innerText);
      if (intNumber) {
        span.innerText = new Intl.NumberFormat(locale, {
          notation: "compact",
          compactDisplay: "short",
        })
          .format(intNumber)
          .toString();
      }
    });
  }
}
