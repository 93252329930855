import { Controller } from "stimulus";
import { show, hide, closeModals } from "src/common";
import { requestPost } from "lib/request";

import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [
    "listContainer",
    "listPage",
    "newBtn",
    "newListArea",
    "optionsArea",
    "listNewNameInput",
    "inputName",
    "inputId",
  ];

  initialize() {
    this.intersectionObserver = new IntersectionObserver(
      (entries) => this.processIntersectionEntries(entries),
      {
        rootMargin: "50px",
      }
    );
  }

  initLoadButton() {
    // load data when save to list
    let saveListButton = document.querySelector(".modal-save-list-button");
    if (saveListButton) {
      saveListButton.addEventListener("click", () => {
        this.load();
      });
    }
  }

  connect() {
    this.element[this.identifier] = this;
    if (this.hasListPageTarget) {
      this.intersectionObserver.observe(this.listPageTarget);
    }
    this.initLoadButton();
    // load data when edit list
    let editListButtons = document.querySelectorAll(".modal-edit-list-button");
    Array.from(editListButtons).forEach((btn) => {
      let listId = btn.dataset.listId;
      btn.addEventListener("click", () => {
        this.loadListInfo(listId);
      });
    });
  }

  disconnect() {
    if (this.hasListPageTarget) {
      this.intersectionObserver.unobserve(this.listPageTarget);
    }
  }

  processIntersectionEntries(entries) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        this.loadMore();
        // console.log("hit: ", entry.target);
      }
    });
  }

  load() {
    let video_id = this.listContainerTarget.dataset.videoId;
    fetch(`/users/simple_lists?vid=${video_id}`, {
      headers: {
        Accept: "text/json",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
        "X-CSRF-Token": Rails.csrfToken(),
      },
      credentials: "same-origin",
    })
      .then((response) => response.json())
      .then((data) => {
        this.listContainerTarget.innerHTML = data.lists;
        this.listPageTarget.innerHTML = data.page || "";
      });
  }

  loadMore() {
    let next_page = this.listPageTarget.querySelector("a[rel='next']");
    if (next_page == null) {
      return;
    }
    let url = next_page.href;

    Rails.ajax({
      type: "GET",
      url: url,
      dataType: "json",
      success: (data) => {
        this.listContainerTarget.insertAdjacentHTML("beforeend", data.lists);
        this.listPageTarget.innerHTML = data.page;
      },
    });
  }

  loadListInfo(listId) {
    fetch(`/users/list_info?id=${listId}`, {
      headers: { "X-CSRF-Token": Rails.csrfToken() },
      credentials: "same-origin",
    })
      .then((response) => response.json())
      .then((json) => {
        this.inputNameTarget.value = json.name;
        this.inputIdTarget.value = listId;
      });
  }

  updateList() {
    let listId = this.inputIdTarget.value;
    requestPost(
      "/users/update_list",
      {
        id: this.inputIdTarget.value,
        name: this.inputNameTarget.value,
      },
      (data) => {
        if (data.success) {
          let listName = document.querySelector(`#list-${listId} .list-name`);
          listName.textContent = data.name;
          closeModals();
          Toastr.success(data.message);
        } else {
          Toastr.error(data.message);
        }
      }
    );
  }

  newList() {
    hide(this.optionsAreaTarget);
    show(this.newListAreaTarget);
    this.listNewNameInputTarget.focus();
  }

  cancelNewList() {
    show(this.optionsAreaTarget);
    hide(this.newListAreaTarget);
  }

  onCreateSuccess(e) {
    const [data, status, xhr] = e.detail;
    if (xhr.status == 200) {
      this.listContainerTarget.insertAdjacentHTML("beforeend", xhr.response);
      this.listNewNameInputTarget.value = "";
      this.cancelNewList();
      closeModals();
    }
  }

  listCheckboxChanged(e) {
    let target = e.target;
    let videoId = e.target.value;
    let isChecked = e.target.checked;
    let listId = e.target.dataset.listId;
    requestPost(
      "/users/save_video_to_list",
      {
        video_id: videoId,
        checked: isChecked,
        list_id: listId,
      },
      (data) => {
        if (data.success) {
          Toastr.success(data.message);
        } else {
          target.checked = false;
          Toastr.error(data.message);
        }
      }
    );
  }
}
