/* eslint no-console: ["error", "warn", { allow: ["log"] }] */
import "jquery";
import "@babel/polyfill";
import Rails from "@rails/ujs";
Rails.start();
import "intersection-observer";
import "@fancyapps/fancybox";
import "sticky-sidebar";
import "material-scrolltop";
import ClipboardJS from "clipboard";
import "controllers";
import { setActorFilterSlider, setActorFilterSliderChar } from "src/actor";
import "src/search";
import I18n from "i18n-js";
import { initI18n } from "locales/i18n.js";
import Cookies from "js-cookie";

import Toastr from "toastr";
Toastr.options = { positionClass: "toast-bottom-left" };
window.Toastr = Toastr;

import {
  initModals,
  closeModals,
  closeNotification,
  beforeAuth,
  switchTagExpend,
  removeParam,
  getRandomInt,
  currentLocale,
  getTheme,
  switchTheme,
  convertShortNumber,
  repositionCovers,
} from "src/common";
window.initModals = initModals;
window.closeModals = closeModals;

let SmoothScroll = require("smooth-scroll");

import "stylesheets/app";
const images = require.context("../../assets/images", true);
// const importAll = (r) => r.keys().map(r);
// importAll(require.context("images", false, /\.(png|jpe?g|svg)$/i));

if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach;
}

document.addEventListener("DOMContentLoaded", function () {
  // 判断小程序关闭按钮
  if (window.api) {
    window.api.call("close.button.hide", true);
  }
  window.matchMedia("(prefers-color-scheme: dark)").addListener((e) => {
    e.matches && switchTheme();
  });
  window.matchMedia("(prefers-color-scheme: light)").addListener((e) => {
    e.matches && switchTheme();
  });
  switchTheme();

  initI18n();
  I18n.defaultLocale = currentLocale();
  I18n.locale = currentLocale();
  repositionCovers();
  // convertShortNumber(I18n.locale);
});

$(document).ready(function () {
  let domain = window.location.host;
  let pathname = window.location.pathname;
  if (!(domain.indexOf("javdb") >= 0 && domain.endsWith(".com"))) {
    let domain = document.body.dataset.domain;
    if (domain != null && domain != "") {
      window.location.href = domain + pathname;
    }
  }

  // show app banner
  let hideAppBanner = Cookies.get("hide_app_banner");
  let appBanner = document.querySelector(".app-desktop-banner");
  if (appBanner) {
    window.addEventListener("resize", function (e) {
      showSlogen(appBanner);
    });
    let showSlogen = function (appBanner) {
      if (window.screen.width < 450) {
        appBanner.querySelector(".slogen .full").style.display = "none";
        appBanner.querySelector(".slogen .short").style.display = "block";
      } else {
        appBanner.querySelector(".slogen .full").style.display = "block";
        appBanner.querySelector(".slogen .short").style.display = "none";
      }
    };
    showSlogen(appBanner);
    if (hideAppBanner == null || hideAppBanner == undefined) {
      appBanner.style.display = "block";
    }
    let linkContent = document.querySelector(".app-desktop-banner .content");
    if (linkContent) {
      linkContent.style.cursor = "pointer";
      linkContent.addEventListener("click", function (e) {
        let linkHref = document.querySelector(".app-desktop-banner .content")
          .dataset.href;
        window.location.href = linkHref;
      });
    }
  }
  let closeAppBanner = document.querySelector(
    ".app-desktop-banner .close button"
  );
  if (closeAppBanner) {
    closeAppBanner.addEventListener("click", function (e) {
      Cookies.set("hide_app_banner", "1");
      appBanner.style.display = "none";
    });
  }

  // remove aifeedback params
  let url = window.location.href;
  if (window.aifeedback) {
    url = removeParam(url, "rp");
    url = removeParam(url, "rid");
    window.history.pushState({}, document.title, url);
  }

  $("img.rucaptcha-image").click(function () {
    var timestamp = new Date().getTime();
    var src = $(this).attr("src").split("?");
    $(this).attr("src", src[0] + "?" + timestamp);
  });

  // for signup
  let userEmail = document.querySelector("#user_email");
  let userUsername = document.querySelector("#user_username");
  if (userEmail && userUsername) {
    userUsername.addEventListener("focus", function (e) {
      if (userEmail.value.indexOf("@") > 1 && userUsername.value == "") {
        userUsername.value = userEmail.value.split("@")[0];
      }
    });
  }

  // bulma menu toggle
  $(".navbar-burger").click(function () {
    $("#navbar-menu-hero, .navbar-burger").toggleClass("is-active");
  });
  // bulma dropdown toggle
  let dropdowns = document.querySelectorAll(".dropdown");
  if (dropdowns) {
    dropdowns.forEach((d) => {
      d.addEventListener("click", () => {
        d.classList.toggle("is-active");
      });
    });
  }

  // nav toggle
  $(".main-nav .mobile-nav").click(function () {
    let target = $("#" + $(this).data("target"));
    target.toggle();
    // if target is showed
    let spani = $(this).find("span i");
    if (target.css("display") != "none") {
      spani.data("beforeclass", spani.attr("class"));
      spani.attr("class", "icon-remove");
    } else {
      spani.attr("class", spani.data("beforeclass"));
    }
    window.scrollTo(0, 0);
  });

  initModals();

  $(".message-container .delete").click(function () {
    $(this).closest(".message-container").hide();
  });

  $(".notification .delete").click(function () {
    closeNotification(this);
  });

  let clipboard = new ClipboardJS(".copy-to-clipboard");
  clipboard.on("success", function (e) {
    $(e.trigger).addClass("tooltip").attr("data-tooltip", I18n.t("copied"));
    return false;
  });

  $(".button-collect").click(function (e) {
    $(this).addClass("is-loading");
  });

  $(".button-uncollect").click(function (e) {
    $(this).addClass("is-loading");
  });

  if ($("#user-menu").length) {
    new StickySidebar("#user-menu", {
      topSpacing: 20,
    });
  }

  // index tags
  $("#tags dt.tag-category, .actor-tags .content").each(function () {
    if ($(this).hasClass("collapse")) {
      switchTagExpend($(this).find("a"), false);
    } else {
      switchTagExpend($(this).find("a"), true);
    }
  });

  $("#tags a.tag-expand, .actor-tags .content a.tag-expand").click(function () {
    var cid = $(this).data("cid");
    if ($(this).data("active") == "true") {
      $(this).parent().addClass("collapse");
      switchTagExpend($(this), false);
    } else {
      $(this).parent().removeClass("collapse");
      switchTagExpend($(this), true);
    }
  });

  $(".tag-expand-btn").click(function () {
    $(".tag-collapse-dock").hide();
    $("#tags").removeClass("tag-collapse");
  });

  // actor page
  $(".btn-actor-filter").click(function () {
    $(".actor-filter-toolbar").hide();
    $(".actor-filter").show();
  });

  // actor filter
  if (typeof defaultAge !== "undefined") {
    setActorFilterSlider("af-age", "af-age-input", defaultAge, [19, 65]);
  }
  if (typeof defaultHeight !== "undefined") {
    setActorFilterSlider("af-height", "af-height-input", defaultHeight, [
      130,
      185,
    ]);
  }
  if (typeof defaultCup !== "undefined") {
    setActorFilterSliderChar("af-cup", "af-cup-input", defaultCup, [0, 15]);
  }
  if (typeof defaultBust !== "undefined") {
    setActorFilterSlider("af-bust", "af-bust-input", defaultBust, [70, 120]);
  }
  if (typeof defaultWaist !== "undefined") {
    setActorFilterSlider("af-waist", "af-waist-input", defaultWaist, [50, 90]);
  }
  if (typeof defaultHips !== "undefined") {
    setActorFilterSlider("af-hips", "af-hips-input", defaultHips, [70, 120]);
  }

  $(".rating-star label").mouseover(function () {
    $("span.selected-star-title").html($(this).attr("title"));
  });
  $(".rating-star label").mouseout(function () {
    var dataTitle = $("span.selected-star-title").data("title");
    var title = dataTitle ? dataTitle : I18n.t("no_choose");
    $("span.selected-star-title").html(title);
  });
  $(".rating-star label").click(function () {
    var title = $(this).attr("title");
    $("span.selected-star-title").data("title", title).html(title);
  });

  let reviewStatusSwitch = function (val) {
    if (val == "want_watch") {
      $(".rating-star-title").hide();
      $(".rating-star").hide();
      $(".rating-comment").hide();
    } else {
      $(".rating-star-title").show();
      $(".rating-star").show();
      $(".rating-comment").show();
    }
  };

  reviewStatusSwitch($("input[name='video_review[status]']:checked").val());

  $(".review-status").change(function () {
    if ($(this).is(":checked")) {
      reviewStatusSwitch($(this).val());
    }
  });

  // smooth scroll
  var scroll = new SmoothScroll('a[href*="#"]');
  // scrollTop
  $("body").materialScrollTop();
});
