import { Controller } from "stimulus";

export default class extends Controller {
  connect() {}

  openUnpaidTicketsModal(e) {
    let orderNumber = e.currentTarget.dataset.orderNumber;
    let modal = document.getElementById("unpaid-tickets-modal");
    if (modal) {
      let orderNumberField = modal.querySelector("#order_number");
      orderNumberField.value = orderNumber;
      modal.classList.add("is-active");
    }
  }
}
