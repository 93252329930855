import Bloodhound from "bloodhound-js";
import I18n from "i18n-js";
import hotkeys from "hotkeys-js";
require("imports-loader?exports=>false,define=>false!lib/typeahead.js");

// quickly focus search input
hotkeys("f", function (e, handler) {
  event.preventDefault();
  window.scrollTo(0, 0);
  let searchInput = document.getElementById("video-search");
  if (searchInput) {
    searchInput.focus();
  }
});

let searchTypeData = function () {
  return {
    all: {
      ph: I18n.t("movie_search_placeholder"),
      url: "/videos/search_autocomplete.json?q=%QUERY",
    },
    actor: {
      ph: I18n.t("actor_search_placeholder"),
      url: "/actors/search_autocomplete.json?q=%QUERY",
    },
    maker: {
      ph: I18n.t("maker_search_placeholder"),
      url: "/makers/search_autocomplete.json?q=%QUERY",
    },
    director: {
      ph: I18n.t("director_search_placeholder"),
      url: "/directors/search_autocomplete.json?q=%QUERY",
    },
    series: {
      ph: I18n.t("series_search_placeholder"),
      url: "/series/search_autocomplete.json?q=%QUERY",
    },
    code: {
      ph: I18n.t("code_search_placeholder"),
      url: "/video_codes/search_autocomplete.json?q=%QUERY",
    },
  };
};

let searchCurrentType = "all";
let searchUrl = searchTypeData()[searchCurrentType]["url"];
let searchRule = new Bloodhound({
  datumTokenizer: Bloodhound.tokenizers.whitespace,
  queryTokenizer: Bloodhound.tokenizers.whitespace,
  remote: {
    url: searchUrl,
    wildcard: "%QUERY",
  },
});

let initAutoComplete = function (searchUrl, searchCurrentType) {
  searchRule = new Bloodhound({
    datumTokenizer: Bloodhound.tokenizers.whitespace,
    queryTokenizer: Bloodhound.tokenizers.whitespace,
    remote: {
      url: searchUrl,
      wildcard: "%QUERY",
    },
  });

  $("#video-search").typeahead("destroy");
  searchRule.clear();
  $("#video-search").typeahead(
    { minLength: 2, hint: false },
    {
      limit: Infinity,
      source: searchRule,
      display: "number",
      templates: {
        empty: [
          '<div class="empty-message">',
          I18n.t("no_matching_results_found"),
          "</div>",
        ].join("\n"),
        suggestion: function (data) {
          return searchTypeResult(searchCurrentType, data);
        },
      },
    }
  );
};

let renderSingleModelResult = function (actionName, data) {
  return `<div class="item-card" onclick="window.location.href='/${actionName}/${
    data.id
  }';">
        <div class="item-detail">
          <dl>
            <dt class="item-title">${data.name}</dt>
            <dt>${I18n.t("movies_count")}: ${data.videos_count}</dt>
          </dl>
        </div>`;
};

let searchTypeResult = function (t, data) {
  let result = "";
  let uncensored = "";
  switch (t) {
    case "all":
      result = `<div class="item-card" onclick="window.location.href='/v/${data.uid}';">
        <div class="item-cover">
          <img src="${data.cover_url}" />
        </div>
        <div class="item-detail">
          <dl>
            <dt class="item-title">${data.title}</dt>
            <dt>${data.meta}</dt>
            <dt>${data.actors}</dt>
          </dl>
        </div>`;
      break;
    case "actor":
      if (data.uncensored) {
        uncensored = ` (${I18n.t("uncensored")})`;
      }
      result = `
      <div class="item-card" onclick="window.location.href='/actors/${data.id}';">
        <div class="item-avatar">
          <span class="avatar" style="background-image: url('${data.avatar_url}')"></span>
        </div>
        <div class="item-detail has-avatar"><dl>
        <dt class="item-title"> ${data.name}${uncensored} </dt>`;
      if (data.other_name) {
        result += `<dt class="meta">${data.other_name} </dt>`;
      }
      result += `<dt class="meta">${I18n.t("movies_count")}: ${
        data.videos_count
      } </dt></dl></div>`;
      break;
    case "maker":
      result = renderSingleModelResult("makers", data);
      break;
    case "director":
      result = renderSingleModelResult("directors", data);
      break;
    case "series":
      result = renderSingleModelResult("series", data);
      break;
    case "code":
      result = renderSingleModelResult("video_codes", data);
      break;
  }
  return result;
};

let searchSubmit = function () {
  let keyword = $("#video-search").val();
  if (keyword != "") {
    window.location.href = "/search?q=" + keyword + "&f=" + searchCurrentType;
  }
};

$(document).ready(function () {
  $("#search-submit").click(function () {
    searchSubmit();
  });

  $("#video-search").keyup(function (e) {
    e.preventDefault();
    if (e.keyCode === 13 && $(this).val() != "") {
      searchSubmit();
    }
  });

  $("#search-type").change(function () {
    searchCurrentType = $("#search-type option:selected").val();
    $("#video-search").attr(
      "placeholder",
      searchTypeData()[searchCurrentType]["ph"]
    );
    searchUrl = searchTypeData()[searchCurrentType]["url"];
    // initAutoComplete(searchUrl, searchCurrentType);
  });

  // handle search image button
  let buttonSearchImage = document.getElementById("button-search-image");
  let searchImageFile = document.getElementById("search-image-file");
  let selectImageModal = document.getElementById("select-search-image-modal");
  if (buttonSearchImage) {
    buttonSearchImage.addEventListener("click", function () {
      selectImageModal.classList.add("is-active");
    });
  }

  let buttonSearchImages = document.querySelectorAll(".button-search-by-image");
  buttonSearchImages.forEach((btn) => {
    btn.addEventListener("click", function () {
      searchImageFile.dataset.target = btn.dataset.target;
      searchImageFile.click();
    });
  });

  let searchImageModalCloseItems = document.querySelectorAll(
    "#search-image-modal .modal-card-head .delete, #search-image-modal .modal-background"
  );
  searchImageModalCloseItems.forEach((item) => {
    item.addEventListener("click", () => {
      let fileInput = document.getElementById("search-image-file");
      fileInput.value = "";
      let modalBody = document.querySelector(
        "#search-image-modal .modal-card-body"
      );
      modalBody.innerHTML = `<div class="search-image-loading">${I18n.t(
        "searching"
      )}</div>`;
    });
  });

  // handle image for search image
  if (searchImageFile) {
    searchImageFile.addEventListener("change", function () {
      if (searchImageFile.files.length > 0) {
        selectImageModal.classList.remove("is-active");
        let modal = document.getElementById("search-image-modal");
        modal.classList.add("is-active");

        let modalBody = document.querySelector(
          "#search-image-modal .modal-card-body"
        );

        let _URL = window.URL || window.webkitURL;
        // check file size
        let file = searchImageFile.files[0];
        if (file.size > 10000000) {
          modalBody.innerHTML = `<div class="empty-message has-text-centered">${I18n.t(
            "photo_size_exceeds_limit",
            { size: "10MB" }
          )}</div>`;
          return;
        }
        // check image's height and width (for staging)
        let image = new Image();
        var imageWidth,
          imageHeight = 0;
        image.onload = function () {
          imageWidth = this.width;
          imageHeight = this.height;
        };
        image.src = _URL.createObjectURL(file);
        if (imageWidth > 4500 || imageHeight > 4500) {
          modalBody.innerHTML = `<div class="empty-message has-text-centered">${I18n.t(
            "",
            { size: "4500x4500(px)" }
          )}</div>`;
          return;
        }

        let auth_token = document.querySelector('meta[name="csrf-token"]');
        let auth_token_value = auth_token.getAttribute("content");
        let formData = new FormData();
        formData.append("target", searchImageFile.dataset.target);
        formData.append("image", file);
        formData.append("authenticity_token", auth_token_value);
        fetch("/search_image", {
          headers: { "X-CSRF-Token": auth_token_value },
          credentials: "include",
          method: "POST",
          body: formData,
        })
          .then((response) => response.text())
          .then((html) => {
            if (html) {
              let modalBody = document.querySelector(
                "#search-image-modal .modal-card-body"
              );
              if (html.startsWith("window.")) {
                window.location.href = "/login";
              } else {
                modalBody.innerHTML = html;
              }
            }
          })
          .catch(() => {});
      }
    });
  }

  // initAutoComplete(searchUrl, searchCurrentType);
});
