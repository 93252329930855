import I18n from "i18n-js";

export function initI18n() {
  I18n.translations = {};

  I18n.translations["en"] = {
    input_and_select: "Input and choose",
    movie_search_placeholder: "Title/ID/Actor",
    actor_search_placeholder: "Actor name",
    maker_search_placeholder: "Maker name",
    director_search_placeholder: "Director name",
    series_search_placeholder: "Series name",
    code_search_placeholder: "The prefix of ID, for example: SNIS",
    no_matching_results_found: "No matching results found",
    uncensored: "Uncensored",
    searching: "Searching...",
    photo_size_exceeds_limit: "The image size cannot exceed %{size}",
    photo_wh_exceeds_limit: "The image width and height cannot exceed %{size}",
    no_choose: "No choose",
    copied: "Copied",
    collapse: "Collapse",
    more: "More",
    system_error:
      "An error report has been dispatched, Please contact administrator through the feedback page",
    movies_count: "Movies Count",
    gender0: "Female",
    gender1: "Male",
  };

  I18n.translations["zh-TW"] = {
    input_and_select: "輸入並選擇",
    movie_search_placeholder: "標題、番號、演員名",
    actor_search_placeholder: "演員名",
    maker_search_placeholder: "片商名",
    director_search_placeholder: "導演名",
    series_search_placeholder: "系列名",
    code_search_placeholder: "輸入番號前綴, 如: SNIS",
    no_matching_results_found: "沒找到匹配的結果",
    uncensored: "無碼",
    searching: "檢索中...",
    photo_size_exceeds_limit: "文件大小不能超過%{size}",
    photo_wh_exceeds_limit: "文件寬高不能超過%{size}",
    no_choose: "未選",
    copied: "已復制",
    collapse: "收起",
    more: "更多",
    system_error: "遇到錯誤，請通過反饋功能聯系管理員",
    movies_count: "影片數",
    gender0: "女優",
    gender1: "男優",
  };
}
