import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["showOriginTitle"];

  connect() {
    let self = this;
    if (this.hasShowOriginTitleTarget) {
      this.showOriginTitleTarget.addEventListener("click", (e) => {
        let originTitle = self.element.querySelector(".origin-title").innerText;
        self.element.querySelector(".current-title").innerText = originTitle;
        this.showOriginTitleTarget.style.display = "none";
      });
    }
  }
}
