import Rails from "@rails/ujs";

export function requestPost(url, data, callback) {
  fetch(url, {
    method: "post",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": Rails.csrfToken(),
    },
    credentials: "same-origin",
  })
    .then((response) => response.json())
    .then((data) => {
      if (callback) {
        callback(data);
      }
    });
}
