import noUiSlider from "nouislider";

export function setActorFilterSlider(divID, inputID, defaultScope, maxScope) {
  $("#" + inputID).val(defaultScope.join(","));
  var slider = document.getElementById(divID);
  if (slider) {
    noUiSlider
      .create(slider, {
        start: defaultScope,
        connect: true,
        tooltips: true,
        format: wNumb({ decimals: 0 }),
        margin: 5,
        range: {
          min: maxScope[0],
          max: maxScope[1],
        },
      })
      .on("change.one", function (values, handle, unencoded, tap, positions) {
        $("#" + inputID).val(values.join(","));
      });
  }
}

export function setActorFilterSliderChar(
  divID,
  inputID,
  defaultScope,
  maxScope
) {
  $("#" + inputID).val(defaultScope.join(","));
  var range = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
  ];
  var slider = document.getElementById(divID);
  if (slider) {
    slider = noUiSlider
      .create(slider, {
        start: defaultScope,
        connect: true,
        tooltips: true,
        step: 1,
        format: {
          to: function (value) {
            return range[parseInt(value)];
          },
          from: Number,
        },
        margin: 1,
        range: {
          min: maxScope[0],
          max: maxScope[1],
        },
      })
      .on("change.one", function (values, handle, unencoded, tap, positions) {
        unencoded[0] = parseInt(unencoded[0]);
        unencoded[1] = parseInt(unencoded[1]);
        $("#" + inputID).val(unencoded.join(","));
      });
  }
}
