import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    let self = this;
    let selects = this.element.querySelectorAll("select.nav");
    if (selects) {
      selects.forEach((select) => {
        select.addEventListener("change", (e) => {
          let target = e.currentTarget;
          let url = decodeURI(target.dataset.url).replace("%s", target.value);
          window.location.href = url;
        });
      });
    }
  }
}
