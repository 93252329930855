import { Controller } from "stimulus";
import { show, hide, toggle, beforeAuth } from "src/common";
import { requestPost } from "lib/request";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [
    "tabsContainer",
    "magnets",
    "reviews",
    "lists",
    "magnetTab",
    "reviewTab",
    "listTab",
    "loading",
  ];

  connect() {
    this.tabs = [
      {
        name: "magnet",
        tab: this.magnetTabTarget,
        container: this.magnetsTarget,
      },
      {
        name: "review",
        tab: this.reviewTabTarget,
        container: this.reviewsTarget,
      },
      {
        name: "list",
        tab: this.listTabTarget,
        container: this.listsTarget,
      },
    ];
  }

  latestReviews(e) {
    this.switchTab("review");
    let getUrl = e.currentTarget.dataset.url;
    if (this.reviewTabTarget.dataset.loaded == "false") {
      this.loadingTarget.style.display = "block";
      fetch(getUrl, {
        method: "GET",
        headers: { "X-CSRF-Token": Rails.csrfToken() },
        credentials: "same-origin",
      })
        .then((response) => response.text())
        .then((html) => {
          if (html.indexOf("window.location.href") > -1) {
            const loginRegex = /^window\.location\.href='(.+)'/;
            let result = loginRegex.exec(html);
            if (result.length > 1) {
              window.location.href = result[1];
            }
          } else {
            this.reviewTabTarget.dataset.loaded = "true";
            this.loadingTarget.style.display = "none";
            this.reviewsTarget.innerHTML = html;
          }
        });
    } else {
      this.switchTab("review");
    }
  }

  relatedLists(e) {
    this.switchTab("list");
    let getUrl = e.currentTarget.dataset.url;
    let plansUrl = e.currentTarget.dataset.plansUrl;
    if (this.listTabTarget.dataset.loaded == "false") {
      this.loadingTarget.style.display = "block";
      fetch(getUrl, {
        method: "GET",
        headers: { "X-CSRF-Token": Rails.csrfToken() },
        credentials: "same-origin",
      })
        .then((response) => response.text())
        .then((html) => {
          if (html === "need_vip") {
            window.location.href = plansUrl;
          } else if (html.indexOf("window.location.href") > -1) {
            const loginRegex = /^window\.location\.href='(.+)'/;
            let result = loginRegex.exec(html);
            if (result.length > 1) {
              window.location.href = result[1];
            }
          } else {
            this.listTabTarget.dataset.loaded = "true";
            this.loadingTarget.style.display = "none";
            this.listsTarget.innerHTML = html;
          }
        });
    } else {
      this.switchTab("list");
    }
  }

  magnets(e) {
    this.switchTab("magnet");
  }

  switchTab(name) {
    this.tabs.forEach((tab) => {
      if (tab.name === name) {
        tab.tab.classList.add("is-active");
        if (tab.container) {
          tab.container.style.display = "block";
        }
      } else {
        tab.tab.classList.remove("is-active");
        if (tab.container) {
          tab.container.style.display = "none";
        }
      }
    });
  }
}
