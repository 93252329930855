import { Controller } from "stimulus";
import Cookies from "js-cookie";

export default class extends Controller {
  static targets = [];

  connect() {
    let key = this.element.dataset.cookieKey;
    let isExpand = Cookies.get(key) !== "1";
    let container = document.querySelector(
      `#${this.element.dataset.containerId}`
    );
    if (container && isExpand) {
      // expand
      container.style.display = "grid";
      this.element.querySelector(".text.collapse").style.display = "block";
      this.element.querySelector(".text.expand").style.display = "none";
    } else {
      // collapse
      container.style.display = "none";
      this.element.querySelector(".text.collapse").style.display = "none";
      this.element.querySelector(".text.expand").style.display = "block";
    }
  }

  collapse(e) {
    let target = e.currentTarget;
    let key = this.element.dataset.cookieKey;
    let isExpand = Cookies.get(key) !== "1";
    let container = document.querySelector(
      `#${this.element.dataset.containerId}`
    );
    this.setStatus(this.element.dataset.containerId, key, isExpand, true);
  }

  setStatus(containerId, key, isExpand, setCookie) {
    let container = document.getElementById(containerId);
    if (container && isExpand) {
      // collapse
      container.style.display = "none";
      this.element.querySelector(".text.collapse").style.display = "none";
      this.element.querySelector(".text.expand").style.display = "block";
      if (setCookie) {
        Cookies.set(key, "1", { expires: 7 });
      }
    } else {
      // expand
      container.style.display = "grid";
      this.element.querySelector(".text.collapse").style.display = "block";
      this.element.querySelector(".text.expand").style.display = "none";
      if (setCookie) {
        Cookies.remove(key);
      }
    }
  }
}
