import Cookies from "js-cookie";
import { Controller } from "stimulus";
import { showModal, hideModal } from "src/common";

export default class extends Controller {
  static targets = ["accept"];

  connect() {
    let modal = document.getElementById("modal-comment-warning");
    let watchedModal = document.getElementById("review-watched");
    let self = this;
    if (watchedModal) {
      watchedModal.addEventListener("click", function () {
        let showWarning = Cookies.get("comment_warning") != "1";
        if (showWarning) {
          self.acceptTarget.disabled = true;
          modal.querySelector("footer .button").classList.add("is-disabled");
          showModal(modal);
          self.startTimer(function () {});
        }
      });
    }

    if (this.hasAcceptTarget) {
      this.acceptTarget.addEventListener("click", function () {
        Cookies.set("comment_warning", "1", { expires: 365 });
        hideModal(modal);
      });
    }
  }

  startTimer(callback) {
    let waitTime = 20;
    let currentTime = 0;
    let timerContainer = this.acceptTarget.querySelector("span");
    let self = this;
    let timer = setInterval(function () {
      let remainTime = waitTime - currentTime;
      timerContainer.innerText = ` (${remainTime})`;
      currentTime += 1;
      if (remainTime <= 0) {
        clearInterval(timer);
        timerContainer.innerText = "";
        currentTime = 0;
        self.acceptTarget.disabled = false;
        self.acceptTarget.classList.remove("is-disabled");
        if (typeof callback === "function") {
          callback();
        }
      }
    }, 1000);
  }
}
